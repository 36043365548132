<template>
  <!-- <dv-full-screen-container> -->

  <div class="content bg" v-loading="loading">
    <!-- <dv-loading v-if="loading">正在加载...</dv-loading> -->
    <!-- 全屏容器 -->
    <!-- 第一行 -->
    <div class="module-box">
      <div class="title-time">{{ myTime }} </div>
      <div class="title-data">腾小云AI矩阵获客系统大屏数据</div>
    </div>
    <!-- 第二行 -->
    <div class="module-box2">
      <!-- 左 -->
      <div class="onelineLeft">
        <div class="platform">
          <div class="trill">
            <div class="logoName">抖音</div>
            <div class="sumsBox">
              <span class="sum">
                {{ dy_info.total_play }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(dy_info.total_play)" :duration="3000" id="myCountTo"></countTo> -->
              </span>
              <span class="texts">播放</span>
            </div>
            <!-- <div class="dzpl">
              <div class="dz">
                <span class="sum">0</span>
                <span class="texts">点赞</span>
              </div>
              <div class="dz">
                <span class="sum">0</span>
                <span class="texts">评论</span>
              </div>
            </div> -->
          </div>
          <div class="quickWorker">
            <div class="logoName">快手</div>
            <div class="sumsBox">
              <span class="sum">
                {{ ks_info.total_play }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(ks_info.total_play)" :duration="3000"></countTo> -->
              </span>
              <span class="texts">播放</span>
            </div>
            <!-- <div class="dzpl">
              <div class="dz">
                <span class="sum">0</span>
                <span class="texts">点赞</span>
              </div>
              <div class="dz">
                <span class="sum">0</span>
                <span class="texts">评论</span>
              </div>
            </div> -->
          </div>
        </div>
        <div id="Statistics" ref="myChart"></div>
      </div>
      <!-- 中 -->
      <div class="onelinemain">
        <div class="totalData">
          <div class="top">
            <div class="sum">
              {{ meruserPlayCount }}
              <!-- <countTo :startVal="startVal" :endVal="meruser_data.play_count" :duration="3000"></countTo> -->
              <!--              <countTo :startVal="startVal" :endVal="parseInt(meruser_data.play_count)" :duration="3000"></countTo>-->
            </div>
            <div class="title">总播放量</div>
          </div>
          <div class="bottom">
            <div class="top1">
              <div class="sum">
                {{ meruser_data.comment_count }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(meruser_data.comment_count)" :duration="3000"></countTo> -->
              </div>
              <div class="title">总评论数</div>
            </div>
            <div class="top2">
              <div class="sum">
                {{ meruser_data.sx_count }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(meruser_data.sx_count)" :duration="3000"></countTo> -->
              </div>
              <div class="title">总私信数</div>
            </div>
          </div>
        </div>
        <div class="countlove">
          <div class="boxs">
            <div class="title">授权</div>
            <div class="sum">
              <span class="number">
                {{ auth_count }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(auth_count)" :duration="3000"></countTo> -->
              </span>
            </div>
          </div>
          <div class="boxs">
            <div class="title">剪辑</div>
            <div class="sum">
              <span class="number">
                {{ meruser_data.jj_count }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(meruser_data.jj_count)" :duration="3000"></countTo> -->
              </span>
            </div>
          </div>
          <div class="boxs">
            <div class="title">发布</div>
            <div class="sum">
              <span class="number">
                {{ meruser_data.video_count }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(meruser_data.video_count)" :duration="3000"></countTo> -->
              </span>
            </div>
          </div>
        </div>
        <div class="letterSum">
          <div class="box">
            <div class="title">挂载</div>
            <div class="sum">
              <span class="number">
                {{ meruser_data.mount_count }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(meruser_data.mount_count)" :duration="3000"></countTo> -->
              </span>
            </div>
          </div>
          <div class="box">
            <div class="title">点赞</div>
            <div class="sum">
              <span class="number">
                {{ meruser_data.like_count }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(meruser_data.like_count)" :duration="3000"></countTo> -->
              </span>
            </div>
          </div>
          <div class="box">
            <div class="title">分享</div>
            <div class="sum">
              <span class="number">
                {{ meruser_data.share_count }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(meruser_data.share_count)" :duration="3000"></countTo> -->
              </span>
            </div>
          </div>
          <div class="box">
            <div class="title">线索</div>
            <div class="sum">
              <span class="number">
                {{ meruser_data.xs_count }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(meruser_data.xs_count)" :duration="3000"></countTo> -->
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- 右 -->
      <div class="onelineright">
        <div class="platform">
          <!-- <div class="trill">
            <div class="logoName">爆店码</div>
            <div class="sumsBox">
              <span class="sum">
                {{ bp_info.total_play }}
                <countTo :startVal="startVal" :endVal="parseInt(bp_info.total_play)" :duration="3000"></countTo>
              </span>
              <span class="texts">播放</span>
            </div>
           <div class="dzpl">
              <div class="dz">
                <span class="sum">0</span>
                <span class="texts">点赞</span>
              </div>
              <div class="dz">
                <span class="sum">0</span>
                <span class="texts">评论</span>
              </div>
             </div>
          </div> -->
          <div class="trill">
            <div class="logoName">小红薯</div>
            <div class="sumsBox">
              <span class="sum">
                {{ xhs_info.total_play }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(bp_info.total_play)" :duration="3000"></countTo> -->
              </span>
              <span class="texts">播放</span>
            </div>
            <!-- <div class="dzpl">
              <div class="dz">
                <span class="sum">0</span>
                <span class="texts">点赞</span>
              </div>
              <div class="dz">
                <span class="sum">0</span>
                <span class="texts">评论</span>
              </div>
            </div> -->
          </div>
          <div class="quickWorker">
            <div class="logoName">视频号</div>
            <div class="sumsBox">
              <span class="sum">
                {{ sph_info.total_play }}
                <!-- <countTo :startVal="startVal" :endVal="parseInt(sph_info.total_play)" :duration="3000"></countTo> -->
              </span>
              <span class="texts">播放</span>
            </div>
            <!-- <div class="dzpl">
              <div class="dz">
                <span class="sum">0</span>
                <span class="texts">点赞</span>
              </div>
              <div class="dz">
                <span class="sum">0</span>
                <span class="texts">评论</span>
              </div>
            </div> -->
          </div>
        </div>
        <div class="Statistics">
          <span class="title">用户播放排名</span>
          <dv-scroll-board :config="config"
            style="width: 480px; height: 368px; margin-top: 12px; margin-left: 12px; float: left" ref="scrollBoard" />
        </div>
      </div>
    </div>
    <!-- 第三行 -->
    <div class="module-box3">
      <div id="issue" ref="issueChart"></div>
      <div id="videoData2" ref="videoChart"></div>
    </div>
  </div>

  <!-- </dv-full-screen-container> -->
</template>

<script>
import * as echarts from 'echarts'
import { createEChartsChart } from '../assets/js/echarts'
import { bigScreen } from '@/api/bgScreen'
// import countTo from 'vue-count-to'
export default {
  // components: { countTo },
  data() {
    return {
      config1: [],
      dy_info: {
        total_play: 0,
        total_like: 0,
        total_share: 0,
        total_comment: 0
      },
      bp_info: {
        total_play: 0,
        total_like: 0,
        total_share: 0,
        total_comment: 0
      },
      ks_info: {
        total_play: 0,
        total_like: 0,
        total_share: 0,
        total_comment: 0
      },
      sph_info: {
        total_play: 0,
        total_like: 0,
        total_share: 0,
        total_comment: 0
      },
      xhs_info: {
        total_play: 0,
        total_like: 0,
        total_share: 0,
        total_comment: 0
      },
      videoDataOptions: {
        title: {
          text: '视频数据',
          textStyle: {
            color: '#FFF',
            fontWeight: 'normal'
          },
          left: '20px'
        },
        legend: {
          data: [
            {
              name: '播放量',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: 'rgb(234, 103, 83)'
              }
            },
            {
              name: '点赞量',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#b92bcf'
              }
            },
            {
              name: '评论量',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#1a2fcf'
              }
            },
            {
              name: '分享量',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#984ef9'
              }
            }
          ],
          left: '550px'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '播放量',
            type: 'line',
            stack: 'Total',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(234, 103, 83)'
                },
                {
                  offset: 1,
                  color: 'rgb(170, 62, 116)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [],
            lineStyle: {
              color: '#ea6753'
            }
          },
          {
            name: '点赞量',
            type: 'line',
            stack: 'Total',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(244, 57, 250)'
                },
                {
                  offset: 1,
                  color: 'rgb(181, 36, 221)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [],
            lineStyle: {
              color: '#f939fd'
            }
          },
          {
            name: '评论量',
            type: 'line',
            stack: 'Total',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(9, 40, 235)'
                },
                {
                  offset: 1,
                  color: 'rgb(71, 81, 186)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [],
            lineStyle: {
              color: '#0526f0'
            }
          },
          {
            name: '分享量',
            type: 'line',
            stack: 'Total',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(134, 69, 225)'
                },
                {
                  offset: 1,
                  color: 'rgb(63, 37, 158)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [],
            lineStyle: {
              color: '#984ef9' // 设置折线颜色为红色
            }
          }
        ]
      },
      issueOptions: {
        title: {
          text: '发布',
          textStyle: {
            color: '#FFF',
            fontWeight: 'normal'
          },
          left: '20px'
        },
        legend: {
          data: [
            {
              name: '小红薯',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#fac858'
              }
            },
            {
              name: '视频号',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#ee6666'
              }
            },
            {
              name: '爆店码',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#5d4037'
              }
            },
            {
              name: '快手',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#91cc75'
              }
            },
            {
              name: '抖音',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#5470c6'
              }
            }
          ],
          left: '480px'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '小红薯',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(250, 200, 88)'
                },
                {
                  offset: 1,
                  color: 'rgb(250, 200, 88)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [],
            lineStyle: {
              color: '#fac858'
            }
          },
          {
            name: '视频号',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(238, 102, 102)'
                },
                {
                  offset: 1,
                  color: 'rgb(238, 102, 102)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [],
            lineStyle: {
              color: '#ee6666'
            }
          },
          {
            name: '爆店码',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(93, 64, 55)'
                },
                {
                  offset: 1,
                  color: 'rgb(93, 64, 55)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [],
            lineStyle: {
              color: '#5d4037'
            }
          },
          {
            name: '快手',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(145, 204, 117)'
                },
                {
                  offset: 1,
                  color: 'rgb(145, 204, 117)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [],
            lineStyle: {
              color: '#91cc75'
            }
          },
          {
            name: '抖音',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(84, 112, 198)'
                },
                {
                  offset: 1,
                  color: 'rgb(84, 112, 198)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [],
            lineStyle: {
              color: '#5470c6'
            }
          }
        ]
      },
      statisticsOptions: {
        title: {
          text: '授权统计',
          textStyle: {
            color: '#FFF',
            fontWeight: 'normal'
          },
          left: '20px'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center',
          textStyle: {
            color: '#fff'
          },
          data: [
            {
              name: 'D音',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#5470c6'
              }
            },
            {
              name: 'K手',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#91cc75'
              }
            },
            {
              name: 'X红书号',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#ffdc60'
              }
            },
            {
              name: 'S频号',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#ee6666'
              }
            },
            {
              name: '扫码',
              icon: 'square',
              itemWidth: 20,
              itemHeight: 20,
              textStyle: {
                color: '#9E9E9E'
              },
              itemStyle: {
                color: '#5d4037'
              }
            }
          ]
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['50%', '30%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'outside', //将标签文本放置在饼状图的外部
              formatter: function (params) {
                return params.name + '(' + params.value + ')'
              },
              fontSize: 12,
              color: '#FFF',
              rotate: 30 // 将标签旋转 45 度
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: 'normal'
              }
            },
            labelLine: {
              show: true,
              length: 90, // 设置指示线的长度，单位为像素
              lineStyle: {
                width: 4, //指示线的粗细
                type: 'solid', //指示线的类型,可以是'solid','dashed','dotted'等
                endCap: 'square',
                emphasis: {
                  width: 4,
                  color: 'blue',
                  type: 'solid',
                  endCap: 'square'
                }
              }
            },
            data: [
              { value: 0, name: 'D音', icon: 'square' },
              { value: 0, name: 'K手', icon: 'square' },
              { value: 0, name: 'X红书', icon: 'square' },
              { value: 0, name: 'S频号', icon: 'square' },
              { value: 0, name: '扫码', icon: 'square', itemStyle: { color: '#5d4037' } }
            ]
          }
        ],
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: '45%',
            style: {
              text: '总人数',
              fill: '#fff',
              fontSize: 12
            }
          },
          {
            type: 'text',
            left: 'center',
            top: '49%',
            style: {
              text: 0,
              fill: '#fff',
              fontSize: 20
            }
          }
        ]
      },
      myTime: '', // 一个时间字符串
      config: {
        header: ['用户', '播放数', '点赞数', '评论数', '分享数'],
        headerBGC: '#452183',
        oddRowBGC: '#17124b',
        evenRowBGC: '#17124b',
        headerBGC: '#371c70',

        data: []
      },
      auth_count: 0,
      meruser_data: {
        address_mount: '0',
        auth_count: '0',
        comment_count: '0',
        dy_auth_count: '0',
        h5_auth_count: '0',
        jj_count: '0',
        ks_auth_count: '0',
        like_count: '0',
        play_count: '0',
        pllz_count: '0',
        share_count: '0',
        sph_auth_count: '0',
        sx_count: '0',
        sxlz_count: '0',
        video_count: '0',
        xcx_mount: '0',
        xhs_auth_count: '0',
        mount_count: 0,
        xs_count: 0
      },
      title: "",
      startVal: 0, //初始数值
      endVal: 700, //结束数值
      loading: true,
      meruserPlayCount: 0,
    }
  },

  created() {
    this.startRandomTimer()
  },
  mounted() {
    // 找到CountTo插件的元素
    // var countToElement = document.querySelector('countTo') // 假设这是插件的选择器
    // const countToElement = document.getElementById('myCountTo')
    // if (countToElement) {
    //   countToElement.remove()
    // }
    // 监听CountTo插件的加载完成事件
    // countToElement.addEventListener('load', function () {
    //   // 加载完成后隐藏插件

    //   countToElement.style.display = 'none'
    // })

    createEChartsChart('issue', this.issueOptions)
    createEChartsChart('videoData2', this.videoDataOptions)
    createEChartsChart('Statistics', this.statisticsOptions)

    this.$nextTick(() => {
      this.getCates()
    })
    this.updateClock()
    setInterval(this.updateClock, 1000)
  },
  methods: {
    //修改浏览器的名字和图标
    getUserNameIco(ico, name) {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = ico; // 图片放public目录
      let dhl = window.document.querySelector("title");
      dhl.innerText = name;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    formatter(number) {
      const numbers = number.toString().split('').reverse()
      const segs = []

      while (numbers.length) segs.push(numbers.splice(0, 3).join(''))

      return segs.join(',').split('').reverse().join('')
    },
    updateClock() {
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const day = String(now.getDate()).padStart(2, '0')
      const hours = String(now.getHours()).padStart(2, '0')
      const minutes = String(now.getMinutes()).padStart(2, '0')
      const seconds = String(now.getSeconds()).padStart(2, '0')
      this.myTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
    },

    async getCates() {
      this.loading = true
      const { code, meruser_data, month, msg, user_list, count_info, title, icon } = await bigScreen()
      if (code === 1) {
        this.title = title
        this.getUserNameIco(icon, this.title)
        this.meruser_data = meruser_data
        this.meruser_data.mount_count = parseInt(meruser_data.address_mount) + parseInt(meruser_data.xcx_mount)
        this.meruser_data.xs_count = parseInt(meruser_data.sxlz_count) + parseInt(meruser_data.pllz_count)
        var auth_count = parseInt(meruser_data.dy_auth_count) + parseInt(meruser_data.h5_auth_count) + parseInt(meruser_data.sph_auth_count) + parseInt(meruser_data.xhs_auth_count)
        this.auth_count = auth_count
        console.log(auth_count, 'auth_countauth_countauth_count')
        // this.statisticsOptions.graphic[1].style.text = auth

        this.loading = false
        // setTimeout(() => {
        // 请求成功后的逻辑
        // }, 2000) // 2秒后隐藏加载框

        // 每隔1小时执行一次更新
        setTimeout(() => {
          const randomIncrement = Math.floor(Math.random() * 21) // 生成0到20的随机数

          this.auth_count = (parseInt(this.auth_count) || 0) + randomIncrement
          console.log(meruser_data.h5_auth_count, 'oooo')
          // 再次调度下一个随机更新
          this.getCates()
        }, 3600000) // 1小时 = 3600000毫秒

        this.dy_info = count_info.dy_info
        console.log(this.dy_info, 'this.dy_info')
        this.bp_info = count_info.bp_info
        this.ks_info = count_info.ks_info
        this.sph_info = count_info.sph_info
        this.xhs_info = count_info.xhs_info

        var user_data_list = []
        console.log(user_list, 'opopp')
        for (var i = 0; i < user_list.length; i++) {
          user_data_list.push([user_list[i].nickname, user_list[i].play_num, user_list[i].total_like, user_list[i].total_comment, user_list[i].total_share])
        }
        // this.config.data = user_data_list;
        this.$refs['scrollBoard'].updateRows(user_data_list, 0)

        // 创建一个新数组，包含所有对象的 xhs_release 值
        const xhsReleaseArray = month.video_list.map((item) => item.xhs_release) //小红薯
        const sph_releaseArray = month.video_list.map((item) => item.sph_release) //视频号
        const h5_releaseArray = month.video_list.map((item) => item.h5_release) //爆店码
        const ks_releaseArray = month.video_list.map((item) => item.ks_release) //快手
        const dy_releaseArray = month.video_list.map((item) => item.dy_release) //抖音

        const total_playArray = month.video_list.map((item) => item.total_play) //播放量
        const total_likeArray = month.video_list.map((item) => item.total_like) //点赞
        const total_shareArray = month.video_list.map((item) => item.total_share) //分享
        const total_commentArray = month.video_list.map((item) => item.total_comment) //评论

        const chart = echarts.init(this.$refs.myChart)
        chart.setOption({
          series: [
            {
              // bug 此处指示线不显示是因为数量太少
              data: [
                { value: meruser_data.dy_auth_count, name: 'D音', icon: 'square' },
                { value: meruser_data.ks_auth_count, name: 'K手', icon: 'square' },
                { value: meruser_data.xhs_auth_count, name: 'X红书', icon: 'square' },
                { value: meruser_data.sph_auth_count, name: 'S频号', icon: 'square' },
                { value: meruser_data.h5_auth_count, name: '扫码', icon: 'square', itemStyle: { color: '#5d4037' } }
              ]
            }
          ],
          graphic: [
            {
              type: 'text',
              left: 'center',
              top: '45%',
              style: {
                text: '总人数',
                fill: '#fff',
                fontSize: 12
              }
            },
            {
              type: 'text',
              left: 'center',
              top: '49%',
              style: {
                text: auth_count,
                fill: '#fff',
                fontSize: 20
              }
            }
          ]
        })
        // 发布图
        const chartData = echarts.init(this.$refs.issueChart)
        chartData.setOption({
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: month.video_time
            }
          ],
          series: [
            {
              name: '小红薯',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(250, 200, 88)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(250, 200, 88)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: xhsReleaseArray,
              lineStyle: {
                color: '#fac858'
              }
            },
            {
              name: '视频号',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(238, 102, 102)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(238, 102, 102)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: sph_releaseArray,
              lineStyle: {
                color: '#ee6666'
              }
            },
            {
              name: '爆店码',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(93, 64, 55)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(93, 64, 55)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: h5_releaseArray,
              lineStyle: {
                color: '#5d4037'
              }
            },
            {
              name: '快手',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(145, 204, 117)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(145, 204, 117)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: ks_releaseArray,
              lineStyle: {
                color: '#91cc75'
              }
            },
            {
              name: '抖音',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(84, 112, 198)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(84, 112, 198)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: dy_releaseArray,
              lineStyle: {
                color: '#5470c6'
              }
            }
          ]
        })
        //视频数据图
        const chartVideo = echarts.init(this.$refs.videoChart)
        chartVideo.setOption({
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: month.video_time
            }
          ],
          series: [
            {
              name: '播放量',
              type: 'line',
              stack: 'Total',
              smooth: true,
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(234, 103, 83)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(170, 62, 116)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: total_playArray,
              lineStyle: {
                color: '#ea6753'
              }
            },
            {
              name: '点赞量',
              type: 'line',
              stack: 'Total',
              smooth: true,
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(244, 57, 250)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(181, 36, 221)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: total_likeArray,
              lineStyle: {
                color: '#f939fd'
              }
            },
            {
              name: '评论量',
              type: 'line',
              stack: 'Total',
              smooth: true,
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(9, 40, 235)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(71, 81, 186)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: total_commentArray,
              lineStyle: {
                color: '#0526f0'
              }
            },
            {
              name: '分享量',
              type: 'line',
              stack: 'Total',
              smooth: true,
              showSymbol: false,
              areaStyle: {
                opacity: 0.5,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(134, 69, 225)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(63, 37, 158)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: total_shareArray,
              lineStyle: {
                color: '#984ef9' // 设置折线颜色为红色
              }
            }
          ]
        })
      }else{
        this.$message.error(msg);
      }
    },

    startRandomTimer() {
      this.updateSumRandomly()
      this.updateSumRandomly1()
      this.updateSumRandomly2()
      this.updateSumRandomly3()
      this.updateSumRandomly4()
      this.updateSumRandomly5()
      this.updateSumRandomly6()
      this.updateSumRandomly7()
      this.updateSumRandomly8()
      this.updateSumRandomly9()
      this.updateSumRandomly10()
      this.updateSumRandomly11()
      this.updateSumRandomly12()

    },
    getPlayCount() {
      this.meruserPlayCount = this.dy_info.total_play + this.ks_info.total_play + this.bp_info.total_play + this.sph_info.total_play
    },

    updateSumRandomly() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 6000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 30)
        const randomIncrement = this.getRandomNumber(99, 999)
        console.log(randomIncrement, 'randomIncrementrandomIncrement')
        // 总播放量
        this.meruser_data.play_count = (parseInt(this.meruser_data.play_count) || 0) - randomIncrement
        this.updateSumRandomly()
        this.getPlayCount()
      }, randomInterval)
    },

    updateSumRandomly1() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 4000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 20)
        const randomIncrement = this.getRandomNumber(99, 999)
        // 快手
        this.dy_info.total_play = (parseInt(this.dy_info.total_play) || 0) + randomIncrement
        this.updateSumRandomly1()
        this.getPlayCount()
      }, randomInterval)
    },

    updateSumRandomly2() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 8000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 20)
        const randomIncrement = this.getRandomNumber(99, 999)
        // 快手
        this.ks_info.total_play = (parseInt(this.ks_info.total_play) || 0) + randomIncrement
        this.updateSumRandomly2()
        this.getPlayCount()
      }, randomInterval)
    },
    updateSumRandomly3() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 5000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 20)
        const randomIncrement = this.getRandomNumber(99, 999)
        // 爆店码
        this.bp_info.total_play = (parseInt(this.bp_info.total_play) || 0) + randomIncrement
        this.xhs_info.total_play = (parseInt(this.xhs_info.total_play) || 0) + randomIncrement
        this.updateSumRandomly3()
        this.getPlayCount()
      }, randomInterval)
    },
    updateSumRandomly4() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 7000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 20)
        const randomIncrement = this.getRandomNumber(99, 999)
        // 视频号
        this.sph_info.total_play = (parseInt(this.sph_info.total_play) || 0) + randomIncrement
        this.updateSumRandomly4()
      }, randomInterval)
    },
    updateSumRandomly5() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 6000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 20)
        const randomIncrement = this.getRandomNumber(99, 999)
        // 总评论数
        this.meruser_data.comment_count = (parseInt(this.meruser_data.comment_count) || 0) + randomIncrement
        this.updateSumRandomly5()
        this.getPlayCount()
      }, randomInterval)
    },
    updateSumRandomly6() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 7000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 20)
        const randomIncrement = this.getRandomNumber(99, 999)
        //  总私信数
        this.meruser_data.sx_count = (parseInt(this.meruser_data.sx_count) || 0) + randomIncrement
        this.updateSumRandomly6()
        this.getPlayCount()
      }, randomInterval)
    },
    updateSumRandomly7() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 9000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 20)
        const randomIncrement = this.getRandomNumber(99, 999)
        //  剪辑
        this.meruser_data.jj_count = (parseInt(this.meruser_data.jj_count) || 0) + randomIncrement
        this.updateSumRandomly7()
        this.getPlayCount()
      }, randomInterval)
    },
    updateSumRandomly8() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 6000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 20)
        const randomIncrement = this.getRandomNumber(99, 999)
        //  发布
        this.meruser_data.video_count = (parseInt(this.meruser_data.video_count) || 0) + randomIncrement
        this.updateSumRandomly8()
        this.getPlayCount()
      }, randomInterval)
    },
    updateSumRandomly9() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 8000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 20)
        const randomIncrement = this.getRandomNumber(99, 999)
        //   挂载
        this.meruser_data.mount_count = (parseInt(this.meruser_data.mount_count) || 0) + randomIncrement
        this.updateSumRandomly9()
        this.getPlayCount()
      }, randomInterval)
    },
    updateSumRandomly10() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 7000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 20)
        const randomIncrement = this.getRandomNumber(99, 999)
        //   点赞
        this.meruser_data.like_count = (parseInt(this.meruser_data.like_count) || 0) + randomIncrement
        this.updateSumRandomly10()
        this.getPlayCount()
      }, randomInterval)
    },
    updateSumRandomly11() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 8000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 20)
        const randomIncrement = this.getRandomNumber(99, 999)
        //   分享
        this.meruser_data.share_count = (parseInt(this.meruser_data.share_count) || 0) + randomIncrement
        this.updateSumRandomly11()
        this.getPlayCount()
      }, randomInterval)
    },
    updateSumRandomly12() {
      const minInterval = 1000 // 最小间隔时间（1秒）
      const maxInterval = 6000 // 最大间隔时间（5秒）
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
      setTimeout(() => {
        // const randomIncrement = Math.floor(Math.random() * 20)
        const randomIncrement = this.getRandomNumber(99, 999)
        //  线索
        this.meruser_data.xs_count = (parseInt(this.meruser_data.xs_count) || 0) + randomIncrement
        this.updateSumRandomly12()
        this.getPlayCount()
      }, randomInterval)
    },
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  }
}
</script>
<style lang="scss" scoped>
.module-box {
  width: 100%;
  height: 50px;
  background: url('../assets/img/title.png') no-repeat center center;
  background-size: cover;
  margin-bottom: 23px;
  float: left;
  margin-top: 21px;
  position: relative;

  .title-time {
    position: absolute;
    left: 133px;
    top: 11px;
    font-family: LCD-Ultra, LCD;
    font-weight: 400;
    color: #f7d8ff;
    font-size: 34px;
    text-shadow: 0px 4px 20px #5d00ff, 0px 4px 90px rgba(0, 0, 0, 0.6), 0px 4px 60px #ff31eb;
    -webkit-background-clip: text;
  }

  .title-data {
    font-size: 37px;
    font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
    font-weight: 700;
    line-height: 22px;
    color: #a763ff;
    text-shadow: 0px 4px 38px rgba(142, 56, 255, 0.6);
    background: linear-gradient(1deg, #9d1dff 0%, #a763ff 45%, #636bff 100%);
    -webkit-background-clip: text;
    // margin-left: 849px;
    width: 100%;
    text-align: center;
  }
}

.module-box2 {
  width: 50%;
  display: flex;
  justify-content: space-between;
  /*两端对齐*/
  margin-left: 12px;

  .onelineLeft {
    height: 631px;
    width: 506px;

    .platform {
      display: flex;
      justify-content: space-between;

      .trill {
        width: 247px;
        height: 185px;
        background: url('../assets/img/trill.png') no-repeat !important;
        background-size: cover;
        position: relative;

        .logoName {
          text-align: center;
          font-size: 22px;
          margin-top: 20px;
          font-weight: normal;
        }

        .sumsBox {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;

          .sum {
            font-size: 33.04px;
            font-family: MiSans-Demibold, MiSans;
            font-weight: 600;
            color: #f7d8ff;
            line-height: 26px;
            letter-spacing: 1px;
            text-shadow: 0px 2px 32px #ff09e7, 0px 4px 10px #a509ff;
            -webkit-background-clip: text;
            margin-top: 18px;
          }

          .texts {
            font-size: 12px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            font-weight: 290;
            color: #9a8df9;
            line-height: 22px;
            -webkit-background-clip: text;
            margin-top: 10px;
            margin-left: 2px;
            position: absolute;
            right: 74px;
            bottom: 132px;
          }
        }

        .dzpl {
          display: flex;
          justify-content: space-between;
          width: 65%;
          margin: 0 auto;
          margin-top: 20px;

          .dz {
            .sum {
              font-size: 22px;
              font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
              font-weight: 700;
              color: #d6d0f2;
              line-height: 11px;
              -webkit-background-clip: text;
              margin-top: 18px;
            }

            .texts {
              font-size: 16px;
              font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
              font-weight: 290;
              color: #ffffff;
              -webkit-background-clip: text;
              margin-left: 4px;
              position: relative;
              bottom: 2px;
              position: absolute;
              right: 62px;
              bottom: 112px;
            }
          }
        }
      }

      .quickWorker {
        width: 247px;
        height: 185px;
        background: url('../assets/img/quickWorker.png') no-repeat !important;
        background-size: cover;
        margin-left: 10px;
        position: relative;

        .logoName {
          text-align: center;
          font-size: 22px;
          margin-top: 20px;
          font-weight: normal;
        }

        .sumsBox {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;

          .sum {
            font-size: 33.04px;
            font-family: MiSans-Demibold, MiSans;
            font-weight: 600;
            color: #f7d8ff;
            line-height: 26px;
            letter-spacing: 1px;
            text-shadow: 0px 2px 32px #ff09e7, 0px 4px 10px #a509ff;
            -webkit-background-clip: text;
            margin-top: 18px;
          }

          .texts {
            font-size: 12px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            font-weight: 290;
            color: #9a8df9;
            line-height: 22px;
            -webkit-background-clip: text;
            margin-top: 10px;
            margin-left: 2px;
            position: absolute;
            right: 75px;
            bottom: 132px;
          }
        }

        .dzpl {
          display: flex;
          justify-content: space-between;
          width: 65%;
          margin: 0 auto;
          margin-top: 20px;

          .dz {
            .sum {
              font-size: 22px;
              font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
              font-weight: 700;
              color: #d6d0f2;
              line-height: 11px;
              -webkit-background-clip: text;
            }

            .texts {
              font-size: 16px;
              font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
              font-weight: 290;
              color: #ffffff;
              -webkit-background-clip: text;
              margin-left: 4px;
              position: relative;
              bottom: 2px;
            }
          }
        }
      }
    }

    #Statistics {
      width: 506px;
      height: 446px;
      background: url('../assets/img/Statistics.png') no-repeat !important;
      background-size: cover;
      padding: 10px;
    }
  }

  .onelinemain {
    width: 820px;
    height: 631px;
    margin-left: 14px;

    .totalData {
      width: 820px;
      height: 315px;
      background: url('../assets/img/mains.png');

      .top {
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        top: 88px;

        .sum {
          font-size: 38px;
          font-family: MiSans-Demibold, MiSans;
          font-weight: 600;
          color: #e5ffe4;
          line-height: 18px;
          letter-spacing: 1px;
          text-shadow: 0px 1px 23px #ff09e7, 0px 3px 7px #ff09e7;
          -webkit-background-clip: text;
        }

        .title {
          font-size: 20px;
          font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
          font-weight: 290;
          color: #ffffff;
          line-height: 20px;
          -webkit-background-clip: text;
          margin-top: 20px;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        margin: 0 auto;
        position: relative;
        top: 55px;

        .top1 {
          display: flex;
          flex-direction: column;
          text-align: center;
          position: relative;
          top: 88px;
          width: 100%;

          .sum {
            font-size: 30px;
            font-family: MiSans-Demibold, MiSans;
            font-weight: 600;
            color: #e5ffe4;
            line-height: 18px;
            letter-spacing: 1px;
            text-shadow: 0px 1px 23px #ff09e7, 0px 3px 7px #ff09e7;
            -webkit-background-clip: text;
            position: absolute;
            left: 0;
          }

          .title {
            font-size: 20px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            font-weight: 290;
            color: #ffffff;
            line-height: 20px;
            -webkit-background-clip: text;
            margin-top: 20px;
            position: absolute;
            left: 0;
            top: 20px;
          }
        }

        .top2 {
          display: flex;
          flex-direction: column;
          text-align: center;
          position: relative;
          top: 88px;
          width: 100%;

          .sum {
            font-size: 30px;
            font-family: MiSans-Demibold, MiSans;
            font-weight: 600;
            color: #e5ffe4;
            line-height: 18px;
            letter-spacing: 1px;
            text-shadow: 0px 1px 23px #ff09e7, 0px 3px 7px #ff09e7;
            -webkit-background-clip: text;
            position: absolute;
            right: 0;
          }

          .title {
            font-size: 20px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            font-weight: 290;
            color: #ffffff;
            line-height: 20px;
            -webkit-background-clip: text;
            margin-top: 20px;
            position: absolute;
            right: 0;
            top: 20px;
          }
        }
      }
    }

    .countlove {
      width: 820px;
      height: 141px;
      margin-top: 12px;
      display: flex;
      justify-content: space-between;

      .boxs {
        width: 265px;
        height: 141px;
        padding-left: 22px;
        padding-top: 18px;
        box-sizing: border-box;

        &:nth-child(1) {
          background: url('../assets/img/baoguang.png');
          border-radius: 10px;
        }

        &:nth-child(2) {
          background: url('../assets/img/dianzan.png');
          border-radius: 10px;
        }

        &:nth-child(3) {
          background: url('../assets/img/pinglun.png');
          border-radius: 10px;
        }

        .title {
          font-size: 19px;
          font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
          font-weight: 700;
          color: #ffffff;
          line-height: 19px;
          -webkit-background-clip: text;
        }

        .sum {
          .number {
            font-size: 38px;
            font-family: MiSans-Demibold, MiSans;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
            letter-spacing: 1px;
            -webkit-background-clip: text;
            position: relative;
            top: 41px;
          }

          .dw {
            font-size: 19px;
            font-family: MiSans-Demibold, MiSans;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
            -webkit-background-clip: text;
            position: relative;
            top: 41px;
            left: 5px;
          }
        }
      }
    }

    .letterSum {
      width: 820px;
      height: 141px;
      margin-top: 14px;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 19px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: 700;
        color: #ffffff;
        line-height: 19px;
        -webkit-background-clip: text;
      }

      .box {
        width: 196px;
        height: 141px;
        background: url('../assets/img/fabu.png');
        border-radius: 10px;
        // padding-left: 15px;
        // padding-top: 18px;
        box-sizing: border-box;

        .title {
          padding: 18px 0 0 10px !important;
        }

        .sum {
          .number {
            font-size: 35px;
            font-family: MiSans-Demibold, MiSans;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
            letter-spacing: 1px;
            -webkit-background-clip: text;
            position: relative;
            top: 41px;
            padding: 0 6px !important;
          }

          .dw {
            font-size: 19px;
            font-family: MiSans-Demibold, MiSans;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
            -webkit-background-clip: text;
            position: relative;
            top: 41px;
            left: 5px;
          }
        }
      }
    }
  }

  .onelineright {
    width: 506px;
    height: 631px;
    margin-left: 22px;

    .platform {
      display: flex;
      justify-content: space-between;

      .trill {
        width: 247px;
        height: 185px;
        background: url('../assets/img/trill.png') no-repeat !important;
        background-size: cover;
        position: relative;

        .logoName {
          text-align: center;
          font-size: 22px;
          margin-top: 20px;
          font-weight: normal;
        }

        .sumsBox {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;

          .sum {
            font-size: 33.04px;
            font-family: MiSans-Demibold, MiSans;
            font-weight: 600;
            color: #f7d8ff;
            line-height: 26px;
            letter-spacing: 1px;
            text-shadow: 0px 2px 32px #ff09e7, 0px 4px 10px #a509ff;
            -webkit-background-clip: text;
            margin-top: 18px;
          }

          .texts {
            font-size: 12px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            font-weight: 290;
            color: #9a8df9;
            line-height: 22px;
            -webkit-background-clip: text;
            margin-top: 10px;
            margin-left: 2px;
            position: absolute;
            right: 62px;
            bottom: 132px;
          }
        }

        .dzpl {
          display: flex;
          justify-content: space-between;
          width: 65%;
          margin: 0 auto;
          margin-top: 20px;

          .dz {
            .sum {
              font-size: 22px;
              font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
              font-weight: 700;
              color: #d6d0f2;
              line-height: 11px;
              -webkit-background-clip: text;
            }

            .texts {
              font-size: 16px;
              font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
              font-weight: 290;
              color: #ffffff;
              -webkit-background-clip: text;
              margin-left: 4px;
              position: relative;
              bottom: 2px;
            }
          }
        }
      }

      .quickWorker {
        width: 247px;
        height: 185px;
        background: url('../assets/img/quickWorker.png') no-repeat !important;
        background-size: cover;
        margin-left: 10px;
        position: relative;

        .logoName {
          text-align: center;
          font-size: 22px;
          margin-top: 20px;
          font-weight: normal;
        }

        .sumsBox {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;

          .sum {
            font-size: 33.04px;
            font-family: MiSans-Demibold, MiSans;
            font-weight: 600;
            color: #f7d8ff;
            line-height: 26px;
            letter-spacing: 1px;
            text-shadow: 0px 2px 32px #ff09e7, 0px 4px 10px #a509ff;
            -webkit-background-clip: text;
            margin-top: 18px;
          }

          .texts {
            font-size: 12px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            font-weight: 290;
            color: #9a8df9;
            line-height: 22px;
            -webkit-background-clip: text;
            margin-top: 10px;
            margin-left: 2px;
            position: absolute;
            right: 62px;
            bottom: 132px;
          }
        }

        .dzpl {
          display: flex;
          justify-content: space-between;
          width: 65%;
          margin: 0 auto;
          margin-top: 20px;

          .dz {
            .sum {
              font-size: 22px;
              font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
              font-weight: 700;
              color: #d6d0f2;
              line-height: 11px;
              -webkit-background-clip: text;
            }

            .texts {
              font-size: 16px;
              font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
              font-weight: 290;
              color: #ffffff;
              -webkit-background-clip: text;
              margin-left: 4px;
              position: relative;
              bottom: 2px;
            }
          }
        }
      }
    }

    .Statistics {
      width: 506px;
      height: 446px;
      background: url('../assets/img/Statistics.png') no-repeat !important;
      background-size: cover;

      .title {
        font-size: 15px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        line-height: 34px;
        -webkit-background-clip: text;
        position: relative;
        left: 24px;
        top: 8px;
      }

      .dv-scroll-board {
        position: relative;
        width: 100%;
        height: 100%;
        color: #fff;
        border: 1px solid #6433ce;

        .header {
          background-color: #000 !important;
        }
      }
    }
  }
}

.module-box3 {
  width: 1876px;
  display: flex;
  justify-content: space-between;
  /*两端对齐*/
  margin-left: 12px;
  margin-top: 12px;

  #issue {
    width: 927px;
    height: 329px;
    background: url('../assets/img/dinyue.png') no-repeat;
    background-size: cover;
    padding: 20px;
  }

  #videoData2 {
    width: 927px;
    height: 329px;
    background: url('../assets/img/dinyue.png') no-repeat;
    background-size: cover;
    padding: 20px;
    margin-left: 22px;
  }
}
</style>
<style>
.el-loading-mask {
  background-image: url('../assets/img/pageBg.png') !important;
  background-color: rgba(0, 0, 0, 1) !important;
}
</style>
