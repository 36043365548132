import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view' //全局组件导入
import './assets/css/index.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(dataV)
Vue.use(ElementUI)

Vue.config.productionTip = false

// 引入全局的过滤器
import './utils/filters'

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
