import axios from 'axios'
import qs from 'qs'
import local from '@/utils/local'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { message } from 'statuses'

//1.1 设置超时和基本地址
axios.defaults.baseURL = 'https://video.hxjpayment.cn'
// axios.defaults.baseURL = 'http://192.168.31.101:1235'
axios.defaults.timeout = 60000

// 2.0请求和响应的拦截
axios.interceptors.request.use((config) => {
  NProgress.start()
  const token = local.get('token')
  if (token) {
    // 给当前的这次请求头添加一个字段赋值为token
    config.headers.Authorization = token
  }
  // 每一次的请求都会经过这里
  return config
})

axios.interceptors.response.use((res) => {
  NProgress.done()
  // 响应拦截里面，根据响应的结果，直接在这里同意判断
  const { code, msg } = res.data
  if (msg) {
    if (code === 1 || code === '1') {
      // Message.success(msg)
    } else {
      message.error(msg)
    }
  }
  return res.data
})

// 3.0 导出请求的方法
// 统一传参格式，统一处理成功和失败
export default {
  // 查询数据
  get(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          // 集中处理报错
          reject(err)
        })
    })
  },
  // 增加数据
  post(url, query) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, qs.stringify(query))
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          // 集中处理报错
          reject(err)
        })
    })
  }
}
