// 1.0 引入请求模块
import req from '@/utils/request'

const getUrlParam = (url, paraName) => {
  let arrObj = url.split("?");
  if (arrObj.length > 1) {
    let arrPara = arrObj[1].split("&")
    let arr;
    for (let i in arrPara) {
      arr = arrPara[i].split("=")
      if (arr != null && arr[0] == paraName) {
        return arr[1]
      }
    }
    return ""
  }
  else {
    return ""
  }
}
let token = getUrlParam(window.location.href,'proxy_token')
// 1.1 导出登录请求的方法
export function bigScreen(params) {
  if(token){
    return req.post(`/api/databoard.proxy/proxy_company_screen.html?proxy_token=${token}`, params)
  }else{
    return req.post(`/webapi/screen.datamonitor/company_screen`, params)
  }
}
